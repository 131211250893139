import { defineStore } from 'pinia';

export const useFFStore = defineStore('ffStore', {
  state: () => ({
    featureFlags: {},
  }),
  
  actions: {
    getFeatureFlag(task) {
      return this.featureFlags[task];
    },
    setFeatureFlag(featureFlags) {
      this.featureFlags = featureFlags;
    }
  }
})
