<template>
  <div v-if="modal" class="modal-container">
    <div class="modal-content">
      <img src="../assets/img/cross.svg" alt="close" class="close-button" @click="(status === 'success') ? refreshApp() : (modal = false)">

      <div v-if="status === 'pendingConfirmation'" class="model-content-wrapper">
        <h2 class="text-center space-bottom">
          {{ getTexts('chosenDeclineHeader') }}
        </h2>
        <p class="space-bottom">{{ app.firstName }} ({{ app[UserViewMixin_appIdentifier] }})?</p>
        <div class="space-bottom">
          <p class="text-bold">{{ getTexts('rejectReason') }}:</p>
          <p>{{ getRejectText }}</p>
        </div>
        <div v-if="[true, false].includes(customerVisitToBranch) " class="space-bottom">
          <p class="text-bold"> {{ getTexts('customerVisitToBranch') }}</p>
          {{ getTexts(customerVisitToBranch) }}
        </div>
        <button class="red-button" :disabled="processingReq" @click="reject()"> {{ getButtonLabel('loanRejectedBtn') }} </button>
        <button class="red-button-alt" @click="modal = false"> {{ getButtonLabel('cancelBtn') }} </button>
      </div>

      <div v-else-if="status === 'conflict'">
        <h2 class="space-bottom">{{ getTexts('concurrencyHeader') }}</h2>
        <p class="space-bottom"> {{ getTexts('concurrencyError') }} </p>
        <button class="green-button" @click="refreshList()">{{ getButtonLabel('refreshBtn') }}</button>
      </div>

      <div v-else-if="status === 'error'">
        <h2 class="space-bottom">{{ getTexts('errorHeader') }}</h2>
        <p class="space-bottom">{{ statusMessage }}</p>
        <button class="red-button" @click="modal = false">{{ getButtonLabel('closeBtn') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from '../js/eventBus';
import partnerPortalAPI from '../mixins/apiServices/partnerPortal';
import offerPanelMixin from '../mixins/offerPanelMixin';
import userViewSettingsMixin from '../mixins/userViewSettingsMixin';

import { useAppStore } from '../store/app';

export default {
  mixins: [partnerPortalAPI, offerPanelMixin, userViewSettingsMixin],
  props: {
    stage: String,
    subStage: String,
  },
  data  () {
    return {
      modal: false,
      status: 'pendingConfirmation',
      statusMessage: '',
      rejectReason: undefined,
      rejectTxt: undefined,
      internalReason: '',
      newApps: {},
      appId: '',
      customerVisitToBranch: '',
      isBlacklisted: false,
      appStore: useAppStore(),
    }
  },
  computed: {
    hkRejectReasonRevampFeatureFlags () {
      return this.ffStore.getFeatureFlag('4846');
    },
    app () {
      const { currentProduct, apps, currentTab } = this.appStore;
      const currentAppList = apps[currentProduct][currentTab];
      return currentAppList.find(a => a._id === this.appId);
    },
    getRejectText () {
      if (this.rejectReason && typeof this.rejectReason === 'object') {
        const { category, subCategory, remark } = this.rejectReason;
        let categoryText;
        let subCategoryText;

        if (typeof this.rejectTxt === 'object') {
          const { categoryCopy, subCategoryCopy } = this.rejectTxt;
          categoryText = categoryCopy.text;
          subCategoryText = subCategoryCopy.text;
        }
        const fullRejectText = (remark && category === 'lender_policy' && subCategory === 'others') ? `${categoryText}: ${subCategoryText} - ${remark}` : `${categoryText}: ${subCategoryText}`;
        return fullRejectText
      } else {
        return (this.rejectReason === 'other') ? `${this.rejectReason} - ${this.rejectReasonOther}` : this.rejectTxt;
      }
    },
    getRejectReason () {
      return (this.rejectReasonOther) ? `${this.rejectReason} - ${this.rejectReasonOther}` : this.rejectReason;
    },
  },
  mounted () {
    EventBus.$on('reject-app', this.rejectAppEvent);
  },
  unmounted () {
    EventBus.$off('reject-app', this.rejectAppEvent);
  },
  methods: {
    async reject () {
      this.processingReq = true;

      let reason;
      if (this.locale === 'hk') {
        if (this.hkRejectReasonRevampFeatureFlags) {
          reason = ((this.internalReason) ? this.internalReason : this.rejectReason);
        } else {
          reason = ((this.internalReason) ? this.internalReason : this.getRejectReason);
        }
      };

      if (this.locale === 'sg') {
        reason = this.rejectReason;
      }

      const payload = {
        appId: this.appId,
        product: this.currentProduct,
        reason,
        stage: this.stage,
        subStage: this.subStage,
        customerVisitToBranch: this.customerVisitToBranch,
        isBlacklisted: this.isBlacklisted,
      };

      const { responseCode } = await this.rejectApp(payload);

      if (responseCode === 200) {
        this.processingReq = false;
        return this.$router.go(0);
      }

      if (responseCode === 422) {
        this.status = 'conflict';
        this.statusMessage = 'Conflict, app has already been moved - refresh list';
        this.processingReq = false;
      }
    },

    refreshList () {
      return this.$router.go(0);
    },

    refreshApp () {
      EventBus.$emit('refreshApp', this.newApps);
      this.modal = false;
    },

    rejectAppEvent (payload) {
      this.modal = true;
      this.appId = payload.appId;
      this.rejectReason = payload.reason;
      this.rejectTxt = payload.reasonTxt;
      this.rejectReasonOther = payload.otherReason;
      this.internalReason = payload.internalReason;
      this.customerVisitToBranch = payload.customerVisitToBranch;
      this.isBlacklisted = payload.isBlacklisted;
    }
  }
};
</script>
