<template>
  <div style="width: 100%">
    <div v-if="disburseLoanPanel" class="bottom-spacer">
      <div v-for="(field, index) in fields" :key="index">
        <hr v-if="field.type === 'date' && currentProduct === 'orangeCreditPersonalLoan'" class="bottom-spacer top-spacer">
        <number
          v-if="field.type === 'number'"
          :id="index"
          :key="index"
          :var="offerObj"
          :label="getFieldLabel(index)"
          :error="errorFields"
          :warning="warningFields"
          :prefix="field.prefix"
          :on-change="field.onChange"
          :app-id="appId"
        />
        <date
          v-else-if="field.type === 'date'"
          :id="index"
          :var="offerObj"
          :label="getFieldLabel(index)"
          :error="errorFields"
          :warning="warningFields"
        />
        <readonly
          v-else-if="field.type === 'readOnly'"
          :id="index"
          :var="offerObj"
          :prefix="field.prefix"
          :label="getFieldLabel(index)"
          :default-value="field.defaultValue"
        />
      </div>
    </div>

    <div v-if="rejectLoanPanel" id="reject-panel-wrapper" class="bottom-spacer">
      <div :class="(customerVisitToBranchError) ? 'error-div' : ''">
        <div v-if="isNotOnlineLender" id="ipa-offer-wrapper">
          <div> {{ getTexts('customerVisitToBranch') }} </div>
          <select v-model="customerVisitToBranchModel">
            <option v-for="option in yesNoOptions" :key="option.value" :value="option.value">
              {{ option.text }}
            </option>
          </select>
        </div>
        <div v-if="customerVisitToBranchError" id="error-text"> {{ getTexts('customerVisitToBranchError') }} </div>
      </div>
      <div :class="(rejectReasonError) ? 'error-div' : ''">
        <div id="ipa-offer-wrapper">
          <div v-if="this.hkRejectReasonRevampFeatureFlags && locale === 'hk'">
            <div>
              <div>{{ getTexts('rejectCategory') }}</div>
              <select v-model="rejectCategoryModel">
                <option v-for="option in getDeclineOptions" :key="option.value" :value="option.value">
                  {{ option.text }}
                </option>
              </select>
            </div>
            <div>
              <div>{{ getTexts('rejectSubCategory') }}</div>
              <select v-model="rejectReasonModel" :disabled="hasRejectCategory">
                <option v-for="option in getDeclineSubCategory" :key="option.value" :value="option.value">
                  {{ option.text }}
                </option>
              </select>
            </div>
          </div>

          <div v-else>
            <div v-if="locale === 'sg'">
              <div>
                <div>{{ getTexts('rejectCategory') }}</div>
                <select v-model="rejectCategoryModel">
                  <option v-for="option in getDeclineOptions" :key="option.value" :value="option.value">
                    {{ option.text }}
                  </option>
                </select>
              </div>
              <div>
                <div>{{ getTexts('rejectSubCategory') }}</div>
                <select v-model="rejectReasonModel" :disabled="hasRejectCategory">
                  <option v-for="option in getDeclineSubCategory" :key="option.value" :value="option.value">
                    {{ option.text }}
                  </option>
                </select>
              </div>
            </div>
            <div v-if="locale === 'hk'">
              <div>
                <div>{{ getTexts('rejectReason') }}</div>
                <select v-model="rejectReasonModel">
                  <option v-for="option in getDeclineOptions" :key="option.value" :value="option.value">
                    {{ option.text }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div v-if="rejectReasonError" id="error-text"> {{ getTexts('rejectReasonError') }} </div>
      </div>
      <div :class="(rejectReasonOtherError) ? 'error-div' : ''">
        <div style="margin-top: 10px;">
          <div class="label"> {{ locale === 'sg' ? getTexts('remarks') : getTexts('otherRejectReason') }} </div>
          <textarea v-model="otherRejectReasonModel" />
          <div v-if="rejectReasonOtherError" id="error-text"> {{ getTexts('rejectReasonError') }} </div>
        </div>
      </div>
      <div style="display: flex; align-items: center">
        <checkbox
          id="isBlacklisted"
          :var="blacklistData"
          :error-var="errorFields"
          :label="getTexts('blacklistCheckbox')"
          :default="false"
        />

        <infoTooltip style="margin-left:8px" :body-text="getTexts('blacklistTooltip')" />
      </div>
    </div>

    <div class="cta-buttons-flexbox no-column-gap" style="flex-wrap: wrap">
      <button v-if="!rejectLoanPanel" :style="{ width: showMissedAppointmentButton ? '100%' : '47.5%' }" class="sharp-corner-button green-button" @click="disburse()"> {{ getButtonLabel('loanDisbursedBtn') }}</button>
      <button v-if="showMissedAppointmentButton" style="width: 47.5%" class="sharp-corner-button yellow-button" @click="flagAppointmentMissed()"> {{ getButtonLabel('appointmentMissedBtn') }} </button>
      <button v-if="!disburseLoanPanel" style="width: 47.5%" class="sharp-corner-button red-button" @click="reject()"> {{ getButtonLabel('loanRejectedBtn') }} </button>
      <button v-if="expanded" style="width: 47.5%" class="sharp-corner-button light-grey-button" @click="collapsePanel()"> {{ getButtonLabel('cancelBtn') }} </button>
    </div>
  </div>
</template>

<script>
import EventBus from '../js/eventBus';
import partnerPortalAPI from '../mixins/apiServices/partnerPortal';
import offerPanelMixin from '../mixins/offerPanelMixin';
import checkbox from './inputFields/checkbox.vue';
import infoTooltip from './infoTooltip.vue';

import { useDomainStore } from '../store/domain';
import { useFFStore } from '../store/ffStore';

export default {
  components: {
    checkbox,
    infoTooltip,
  },
  mixins: [partnerPortalAPI, offerPanelMixin],
  props: {
    appId: String,
    appointmentDateTime: String,
    appointmentMissed: Boolean,
  },
  data () {
    return {
      disburseLoanPanel: false,
      rejectLoanPanel: false,
      expanded: false,
      panel: 'acknowledged',
      blacklistData: {
        isBlacklisted: false,
      },
      panelStatus: false,
      domainStore: useDomainStore(),
      ffStore: useFFStore(),
    }
  },
  computed: {
    hkRejectReasonRevampFeatureFlags () {
      return this.ffStore.getFeatureFlag('4846');
    },
    domains () {
      return this.domainStore.getDomains();
    },
    hasRejectCategory () {
      // eslint-disable-next-line no-undef
      return !(this.rejectCategoryModel);
    },
    showMissedAppointmentButton () {
      if (!this.appointmentDateTime || this.appointmentMissed) return false;
      if (this.rejectLoanPanel || this.disburseLoanPanel) return false;
      if (new Date() < new Date(this.appointmentDateTime)) return false;
      return true;
    },
  },
  mounted () {
    EventBus.$on(`update-offer-var-${this.appId}`, this.updateOfferOnApp);
    this.$watch('rejectReason', (newVal) => {
      if (newVal !== 'other') this.rejectReasonOtherError = false;
    });
  },
  unmounted () {
    EventBus.$off(`update-offer-var-${this.appId}`, this.updateOfferOnApp);
  },
  methods: {
    showDisburseLoanPanel () {
      this.disburseLoanPanel = !this.disburseLoanPanel;
      this.expanded = true;
      this.$emit('update:panelStatus', this.panelStatus = true);
    },
    showRejectLoanPanel () {
      this.rejectLoanPanel = !this.rejectLoanPanel;
      this.expanded = true;
    },
    collapsePanel () {
      this.expanded = false;
      this.disburseLoanPanel = false;
      this.rejectLoanPanel = false;
      this.$emit('update:panelStatus', this.panelStatus = false);
    },
    disburse () {
      if (!this.disburseLoanPanel) return this.showDisburseLoanPanel();
      if (!this.validateOfferObj()) return;
      // else
      EventBus.$emit('disburse-app', {
        appId: this.appId,
        product: this.currentProduct, // to anyone reading this in the future, currentProduct is a computed value in partnerPortalAPI mixin...
        postObj: this.offerObj,
        warning: this.warningFields,
      });
    },
    reject () {
      if (!this.rejectLoanPanel) return this.showRejectLoanPanel();

      const rejectReasonFailedValidation = !this.validateRejectReason();

      const customerCameToBranchFailedValidation = !this.validateCustomerCameToBranch();

      if (rejectReasonFailedValidation || customerCameToBranchFailedValidation) return;
      // else

      const emitObj = {
        appId: this.appId,
        product: this.currentProduct,
        otherReason: this.rejectReasonOther,
        customerVisitToBranch: this.customerVisitToBranch,
        isBlacklisted: this.blacklistData.isBlacklisted,
      }

      if (this.hkRejectReasonRevampFeatureFlags && this.locale === 'hk') {
        const categoryCopy = this.rejectTexts.find(d => d.value === this.rejectCategory);
        const subCategoryCopy = this.rejectSubCategory.find(category => category.value === this.rejectReason);
        const texts = { categoryCopy, subCategoryCopy };
        const rejectReasonData = {
          category: this.rejectCategory,
          subCategory: this.rejectReason,
          remark: this.rejectReasonOther,
          isDocReviewed: this.offerObj.documentsReviewed || false,
        }

        emitObj.reason = rejectReasonData;
        emitObj.reasonTxt = texts;
      } else {
        if (this.locale === 'hk') {
          const d = this.rejectTexts.find(d => d.value === this.rejectReason);
          emitObj.reason = this.rejectReason;
          emitObj.reasonTxt = d.value ? d.text : undefined;
        }

        if (this.locale === 'sg') {
          const categoryCopy = this.rejectTexts.find(d => d.value === this.rejectCategory);
          const subCategoryCopy = this.rejectSubCategory.find(category => category.value === this.rejectReason);
          const texts = { categoryCopy, subCategoryCopy };
          const rejectReasonData = {
            category: this.rejectCategory,
            subCategory: this.rejectReason,
            remark: this.rejectReasonOther,
            isDocReviewed: this.offerObj.documentsReviewed || false,
          }

          emitObj.reason = rejectReasonData;
          emitObj.reasonTxt = texts;
        }
      }

      EventBus.$emit('reject-app', emitObj);
    },
    flagAppointmentMissed () {
      const obj = {
        appId: this.appId,
        product: this.currentProduct,
        appointment: this.appointmentDateTime
      };
      EventBus.$emit('missed-appointment-modal', obj);
    },
    validateRejectReason () {
      if (!this.rejectReason) {
        this.rejectReasonError = true;
        return false;
      }

      if ((this.rejectReason === 'other' || (this.rejectCategory === 'lender_policy' && this.rejectReason === 'others')) && (!(/\S/.test(this.rejectReasonOther)) || this.rejectReasonOther === undefined)) {
        this.rejectReasonOtherError = true;
        return false;
      }

      return true;
    },

    updateOfferOnApp ({ field, val }) {
      this.offerObj[field] = val;
    }
  },
}
</script>

<style scoped>
table, div {
  font-size: 0.86rem;
}

input {
  width: 100%;
}

table tr > td:first-child {
  width: 60%;
}

table tr > td:not(:first-child) {
  width: 40%;
}

button {
  margin: 0 !important;
  height: auto;
}
</style>
