<template>
  <div id="offer-revision-wrapper">
    <div v-if="updateIPAPanel" class="bottom-spacer">
      <div v-for="(field, index) in fields" :key="index">
        <number
          v-if="field.type === 'number'"
          :id="index"
          :key="index"
          :var="offerObj"
          :label="getFieldLabel(index)"
          :error="errorFields"
          :warning="warningFields"
          :prefix="field.prefix"
          :on-change="field.onChange"
          :app-id="appId"
          :product-default="field.productDefault"
        />
        <checkbox
          v-else-if="field.type === 'checkbox'"
          :id="index"
          :var="offerObj"
          :label="getFieldLabel(index)"
          :error="errorFields"
          :warning="warningFields"
        />
        <readonly
          v-else-if="field.type === 'readOnly'"
          :id="index"
          :var="offerObj"
          :prefix="field.prefix"
          :label="getFieldLabel(index)"
          :default-value="field.defaultValue"
        />
      </div>
    </div>

    <div v-if="cancelIPAPanel" :class="(rejectReasonError) ? 'error-div' : ''" class="bottom-spacer">
      <div v-if="currentSubTab !== 'offerRevisionGiven'" :class="(customerVisitToBranchError) ? 'error-div' : ''">
        <div v-if="isNotOnlineLender" id="ipa-offer-wrapper">
          <div> {{ getTexts('customerVisitToBranch') }} </div>
          <select v-model="customerVisitToBranchModel">
            <option v-for="option in yesNoOptions" :key="option.value" :value="option.value">
              {{ option.text }}
            </option>
          </select>
        </div>
        <div v-if="customerVisitToBranchError" id="error-text"> {{ getTexts('customerVisitToBranchError') }} </div>
      </div>
      <div :class="(rejectReasonError) ? 'error-div' : ''">
        <div id="ipa-offer-wrapper">
          <div v-if="hkRejectReasonRevampFeatureFlags && locale === 'hk'">
            <div>
              <div>{{ getTexts('rejectCategory') }}</div>
              <select v-model="rejectCategoryModel">
                <option v-for="option in getDeclineOptions" :key="option.value" :value="option.value">
                  {{ option.text }}
                </option>
              </select>
            </div>
            <div>
              <div>{{ getTexts('rejectSubCategory') }}</div>
              <select v-model="rejectReasonModel" :disabled="hasRejectCategory">
                <option v-for="option in getDeclineSubCategory" :key="option.value" :value="option.value">
                  {{ option.text }}
                </option>
              </select>
            </div>
          </div>

          <div v-else>
            <div v-if="locale === 'sg'">
              <div>
                <div>{{ getTexts('rejectCategory') }}</div>
                <select v-model="rejectCategoryModel">
                  <option v-for="option in getDeclineOptions" :key="option.value" :value="option.value">
                    {{ option.text }}
                  </option>
                </select>
              </div>
              <div>
                <div>{{ getTexts('rejectSubCategory') }}</div>
                <select v-model="rejectReasonModel" :disabled="hasRejectCategory">
                  <option v-for="option in getDeclineSubCategory" :key="option.value" :value="option.value">
                    {{ option.text }}
                  </option>
                </select>
              </div>
            </div>
            <div v-if="locale === 'hk'">
              <div>
                <div>{{ getTexts('rejectReason') }}</div>
                <select v-model="rejectReasonModel">
                  <option v-for="option in getDeclineOptions" :key="option.value" :value="option.value">
                    {{ option.text }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div v-if="rejectReasonError" id="error-text">{{ getTexts('rejectReasonError') }}</div>
      </div>
      <div :class="(rejectReasonOtherError) ? 'error-div' : ''">
        <div style="margin-top: 10px;">
          <div class="label">{{ locale === 'sg' ? getTexts('remarks') : getTexts('otherRejectReason') }}</div>
          <textarea v-model="otherRejectReasonModel" style="width: 100%;resize: none" />
          <div v-if="rejectReasonOtherError" id="error-text">{{ getTexts('rejectReasonError') }}</div>
        </div>
      </div>
      <div style="display: flex; align-items: center">
        <checkbox
          id="isBlacklisted"
          :var="blacklistData"
          :error-var="errorFields"
          :label="getTexts('blacklistCheckbox')"
          :default="false"
        />

        <infoTooltip style="margin-left:8px" :body-text="getTexts('blacklistTooltip')" />
      </div>
    </div>

    <checkbox
      v-if="app.uploads"
      id="documentsReviewed"
      :var="offerObj"
      :error-var="errorFields"
      :label="getTexts('docReviewed')"
      :default="false"
      style="margin-bottom: 20px"
    />
    <div class="cta-buttons-flexbox no-column-gap" style="flex-wrap: wrap">
      <button v-if="!ignoreRevisionPanel && !cancelIPAPanel" class="sharp-corner-button green-button" :style="{ width: panelExpanded ? '47.5%' : '100%' }" @click="updateOffer()"> {{ getButtonLabel('reviseIPABtn') }} </button>
      <button v-if="!updateIPAPanel && !cancelIPAPanel" class="sharp-corner-button yellow-button" style="width: 47.5%" @click="ignoreRevision()"> {{ getButtonLabel('ignoreRevisionBtn') }} </button>
      <button v-if="!updateIPAPanel && !ignoreRevisionPanel" class="sharp-corner-button red-button" style="width: 47.5%" @click="cancelIPA()"> {{ getButtonLabel('cancelIPABtn') }} </button>
      <button v-if="panelExpanded" class="sharp-corner-button light-grey-button" style="width: 47.5%" @click="collapsePanel()"> {{ getButtonLabel('cancelBtn') }} </button>
    </div>
  </div>
</template>

<script>
import EventBus from '../js/eventBus';
import offerPanelMixin from '../mixins/offerPanelMixin';
import partnerPortalAPI from '../mixins/apiServices/partnerPortal';
import number from './inputFields/number';
import checkbox from './inputFields/checkbox';
import readonly from './inputFields/readOnly';
import inputWarning from './inputFields/inputWarning';
import infoTooltip from './infoTooltip.vue';

import { useAppStore } from '../store/app';
import { useFFStore } from '../store/ffStore';

export default {
  components: {
    number,
    checkbox,
    readonly,
    inputWarning,
    infoTooltip,
  },

  mixins: [offerPanelMixin, partnerPortalAPI],

  props: {
    appId: String,
    app: Object,
    currentIpaDetails: Object,
  },

  data () {
    return {
      updateIPAPanel: false,
      ignoreRevisionPanel: false,
      cancelIPAPanel: false,
      panelExpanded: false,
      panel: 'offerRevision',
      blacklistData: {
        isBlacklisted: false,
      },
      appStore: useAppStore(),
      ffStore: useFFStore(),
    }
  },

  computed: {
    hkRejectReasonRevampFeatureFlags () {
      return this.ffStore.getFeatureFlag('4846');
    },
    currentSubTab () {
      return this.appStore.currentSubTab;
    },
    hasRejectCategory () {
      // eslint-disable-next-line no-undef
      return !(this.rejectCategoryModel);
    },
  },

  mounted () {
    EventBus.$on(`update-offer-var-${this.appId}`, this.updateOfferOnApp);

    this.$watch('rejectReason', (newVal) => {
      if (newVal !== 'other') this.rejectReasonOtherError = false;
    });
  },

  unmounted () {
    EventBus.$off(`update-offer-var-${this.appId}`, this.updateOfferOnApp);
  },

  methods: {
    updateOffer () {
      if (!this.updateIPAPanel) {
        if (!this.docsReviewed()) return;
        // pre-fill offer fields
        Object.keys(this.fields).forEach((key) => {
          if (this.currentIpaDetails[key] !== undefined) {
            this.offerObj[key] = this.currentIpaDetails[key];
            if (['appliedAmount', 'processingFee', 'monthlyCost', 'nettLoanAmount'].includes(key)) this.offerObj[key] = `${this.offerObj[key]}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          }
        });
        return this.expandPanel('updateIPAPanel');
      }

      if (!this.docsReviewed()) return;
      if (!this.validateOfferObj()) return;

      // Show confirmation pop-up
      EventBus.$emit('give-ipa', {
        appId: this.appId,
        ipaOffer: this.offerObj,
        product: this.currentProduct,
        warning: this.warningFields,
        panel: 'offer-revision',
      });
    },

    ignoreRevision () {
      if (!this.docsReviewed()) return;

      // Show confirmation pop-up
      EventBus.$emit('ignore-revise-ipa', {
        appId: this.appId,
        product: this.currentProduct,
      });
    },

    cancelIPA () {
      if (!this.cancelIPAPanel) {
        if (!this.docsReviewed()) return;
        return this.expandPanel('cancelIPAPanel');
      }

      if (!this.validateRejectReason()) return;
      if (!this.docsReviewed()) return;
      if (this.currentSubTab !== 'offerRevisionGiven' && !this.validateCustomerCameToBranch()) return;

      const emitEvent = (this.currentSubTab === 'offerRevisionGiven' ? 'decline-app' : 'reject-app');

      const emitObj = {
        appId: this.appId,
        product: this.currentProduct,
        otherReason: this.rejectReasonOther,
        panel: 'offer-revision',
        isBlacklisted: this.blacklistData.isBlacklisted,
        ...(emitEvent === 'reject-app') && { customerVisitToBranch: this.customerVisitToBranch },
      }

      if (this.hkRejectReasonRevampFeatureFlags && this.locale === 'hk') {
        const categoryCopy = this.rejectTexts.find(d => d.value === this.rejectCategory);
        const subCategoryCopy = this.rejectSubCategory.find(category => category.value === this.rejectReason);
        const texts = { categoryCopy, subCategoryCopy }
        const internalReason = (this.rejectReasonOther) ? `${this.rejectCategory} - ${this.rejectReason} - ${this.rejectReasonOther}` : `${this.rejectCategory} - ${this.rejectReason}`;

        const declinedReasonData = {
          category: this.rejectCategory,
          subCategory: this.rejectReason,
          remark: this.rejectReasonOther,
          isDocReviewed: this.offerObj.documentsReviewed || false,
        }

        emitObj.reason = declinedReasonData;
        emitObj.reasonTxt = texts;
        emitObj.internalRejectReason = internalReason;
      } else {
        if (this.locale === 'hk') {
          const d = this.rejectTexts.find(d => d.value === this.rejectReason);
          const internalReason = (this.rejectReasonOther) ? `${this.rejectReason} - ${this.rejectReasonOther}` : this.rejectReason;
          emitObj.reason = this.rejectReason;
          emitObj.reasonTxt = d.value ? d.text : undefined;
          emitObj.internalRejectReason = internalReason;
        }

        // Show confirmation pop-up
        if (this.locale === 'sg') {
          const categoryCopy = this.rejectTexts.find(d => d.value === this.rejectCategory);
          const subCategoryCopy = this.rejectSubCategory.find(category => category.value === this.rejectReason);
          const texts = { categoryCopy, subCategoryCopy }
          const internalReason = (this.rejectReasonOther) ? `${this.rejectCategory} - ${this.rejectReason} - ${this.rejectReasonOther}` : `${this.rejectCategory} - ${this.rejectReason}`;

          const declinedReasonData = {
            category: this.rejectCategory,
            subCategory: this.rejectReason,
            remark: this.rejectReasonOther,
            isDocReviewed: this.offerObj.documentsReviewed || false,
          }

          emitObj.reason = declinedReasonData;
          emitObj.reasonTxt = texts;
          emitObj.internalRejectReason = internalReason;
        }
      }

      EventBus.$emit(emitEvent, emitObj);
    },

    docsReviewed () {
      if (this.app.uploads && !this.offerObj.documentsReviewed) {
        this.errorFields.documentsReviewed = true;
        return false;
      }

      return true;
    },

    expandPanel (panel) {
      this[panel] = true;
      this.panelExpanded = true;
    },

    collapsePanel () {
      this.updateIPAPanel = false;
      this.ignoreRevisionPanel = false;
      this.cancelIPAPanel = false;
      this.panelExpanded = false;
    },

    validateRejectReason () {
      if (!this.rejectReason) {
        this.rejectReasonError = true;
        return false;
      }

      if ((this.rejectReason === 'other' || (this.rejectCategory === 'lender_policy' && this.rejectReason === 'others')) && (!(/\S/.test(this.rejectReasonOther)) || this.rejectReasonOther === undefined)) {
        this.rejectReasonOtherError = true;
        return false;
      }

      return true;
    },

    updateOfferOnApp ({ field, val }) {
      this.offerObj[field] = val;
      this.$forceUpdate();
    }
  },
}
</script>

<style scoped>
table, div {
  font-size: 0.86rem;
}

input {
  width: 100%;
}

table tr > td:first-child {
  width: 60%;
}

table tr > td:not(:first-child) {
  width: 40%;
}

button {
  margin: 0 !important;
}
</style>
