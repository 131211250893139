<template>
  <div class="applications-page-wrapper">
    <!-- application table -->
    <div class="table-wrapper">
      <div v-if="isMounted && appStore.apps" id="applications-wrapper">
        <!-- mobile search bar -->
        <div class="mobile-only">
          <div class="mobile-searchbar-wrapper"> <searchbar /> </div>
        </div>

        <!-- tabs -->
        <div>
          <div class="tabs-bar">
            <div
              v-for="tab in Object.keys(tabs)"
              :key="tab"
              :class="(currentTab === tab) ? 'active-tab' : ''"
              @click="changeTab(tab)"
            >
              <div class="tab-content">
                <div>
                  {{ (tabLabels[tabs[tab].id]) ? tabLabels[tabs[tab].id].label : tab }}
                </div>
                <div
                  v-if="sectionLength(tab)"
                  class="circle-div"
                >
                  {{ sectionLength(tab) }}
                </div>
              </div>
            </div>
          </div>

          <div v-if="subTabs[currentTab]" class="tabs-bar" style="margin-top:-10px">
            <div
              v-for="subTab in Object.keys(subTabs[currentTab])"
              :key="subTab"
              :class="(currentSubTab === subTab) ? 'active-tab' : ''"
              @click="changeSubTab(subTab)"
            >
              <div class="tab-content">
                <div>
                  {{ (tabLabels[subTab] && tabLabels[subTab].id) ? tabLabels[subTab].label : subTab }}
                </div>
                <div
                  v-if="sectionLength(subTab)"
                  class="circle-div"
                >
                  {{ sectionLength(subTab) }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- table -->
        <template v-if="appsLoaded">
          <div v-if="currentTab === 'given'" id="application-pagination-div" class="pagination-wrapper">
            <div class="pagination-label"> Show </div>
            <div class="pagination-select-wrapper" :has-suffix="appPaginationSize ? true: false">
              <select v-model="paginationView">
                <option v-for="option in paginationInfo.paginationOptions" :key="option.value" :value="option.value">
                  {{ option.text }}
                </option>
              </select>
            </div>
            <div class="pagination-arrow-wrapper">
              <div :class="(skip === 0) || !appPaginationSize ? 'pagination-disabled' : ''" class="pagination-arrow pagination-button left" @click="getPreviousPageApplication()" />
              <div class="pagination-arrow-divider" />
              <div :class=" paginationNextIsDisabled ? 'pagination-disabled' : ''" class="pagination-arrow pagination-button right" @click="getNextPageApplication()" />
            </div>
          </div>
          <component :is="activeTab" :apps="(currentTab === 'given' && appPaginationSize)? getPaginatedApps: getApps[currentTab]" :fields="getFields" :start-index="(currentTab === 'given' && appPaginationSize)? skip : 0 " />
        </template>

        <div v-else class="loading-wrapper">
          <div> {{ getTexts('fetchingApps') }} </div>
          <i class="loader" />
        </div>

        <myinfo-data-modal />
        <debt-info-modal />
      </div>

      <div v-else class="loading-wrapper">
        <div> {{ getTexts('fetchingApps') }} </div>
        <i class="loader" />
      </div>
    </div>

    <div class="on-screen-menu-wrapper desktop-only">
      <div title="View upcoming appointments"> <appointments-drawer /> </div>
      <div> <performance-drawer /> </div>
      <div v-if="appsLoaded && currentTab !== 'closed'" title="View fields list"> <fields-list-drawer /> </div>
      <div class="flex-fill" />
    </div>
  </div>
</template>

<script>
import EventBus from '../../js/eventBus';
import applicationServices from '../../mixins/apiServices/application';
import contentServices from '../../mixins/apiServices/content';
import authServices from '../../mixins/apiServices/auth';
import leadApps from '../../components/subPages/leadApps';
import givenOfferApps from '../../components/subPages/givenOfferApps';
import offerRevisionApps from '../../components/subPages/offerRevisionApps';
import chosenApps from '../../components/subPages/chosenApps';
import acknowledgedApps from '../../components/subPages/acknowledgedApps';
import closedApps from '../../components/subPages/closedApps';
import fieldCheckbox from '../../components/inputFields/fieldCheckbox';
import myInfoDataModal from '../../components/myInfoDataModal';
import debtInfoModal from '../../components/debtInfoModal';
import searchbar from '../../components/searchbar';
import appointmentsDrawer from '../../components/appointmentsDrawer';
import performanceDrawer from '../../components/performanceDrawer';
import fieldsListDrawer from '../../components/fieldsListDrawer';

import { useAppStore } from '../../store/app';
import { useDomainStore } from '../../store/domain';
import { useNuxtStore } from '../../store/nuxt';
import { useFFStore } from '../../store/ffStore';

export default {
  components: {
    'lead-apps': leadApps,
    'given-offer-apps': givenOfferApps,
    'offer-revision-apps': offerRevisionApps,
    'chosen-apps': chosenApps,
    'acknowledged-apps': acknowledgedApps,
    'closed-apps': closedApps,
    'field-checkbox': fieldCheckbox,
    'myinfo-data-modal': myInfoDataModal,
    searchbar,
    'debt-info-modal': debtInfoModal,
    'appointments-drawer': appointmentsDrawer,
    'performance-drawer': performanceDrawer,
    'fields-list-drawer': fieldsListDrawer,
  },
  mixins: [applicationServices, authServices, contentServices],
  data () {
    return {
      isMounted: false,
      tabs: {
        lead: {
          id: 'leadsApp',
          component: 'lead-apps'
        },
        given: {
          id: 'givenOffersApp',
          component: 'given-offer-apps',
        },
        offerRevision: {
          id: 'offersRevisionApp',
          component: 'offer-revision-apps',
        },
        chosen: {
          id: 'chosenApp',
          component: 'chosen-apps',
        },
        acknowledged: {
          id: 'acknowledgedApp',
          component: 'acknowledged-apps',
        },
        closed: {
          id: 'closedApp',
          component: 'closed-apps',
        },
      },
      subTabs: {
        offerRevision: {
          offerRevisionGiven: {},
          offerRevisionChosen: {},
          offerRevisionAcknowledged: {}
        },
        acknowledged: {
          noAppointments: {},
          hasAppointments: {},
          missedAppointments: {},
        },
      },
      paginationInfo: {
        paginationOptions: [
          { value: 10, text: '10' },
          { value: 20, text: '20' },
          { value: 50, text: '50' },
          { value: null, text: 'All' },
        ],
        currentPage: 1,
        skip: 0,
      },
      scrollHandler: null,
      appStore: useAppStore(),
      domainStore: useDomainStore(),
      nuxtStore: useNuxtStore(),
      ffStore: useFFStore(),
    }
  },
  computed: {
    tabLabels () {
      return this.domainStore.tabLabels();
    },
    miscButtonLabels () {
      return this.domainStore.miscButtonLabels();
    },
    miscTexts () {
      return this.domainStore.miscTexts();
    },
    contentText () {
      return this.domainStore.contentText();
    },
    currentTab () {
      return this.appStore.currentTab;
    },
    currentSubTab () {
      return this.appStore.currentSubTab;
    },
    appPaginationSize () {
      return this.appStore.appPaginationSize;
    },
    paginationNextIsDisabled () {
      const apps = this.getApps;
      const hasAppsInCurrentTab = apps && apps[this.currentTab] && apps[this.currentTab].length;
      const hasNextPageFalse = apps && apps.hasNextPage === false;
      const paginationCapacity = this.getPaginationCapacity;
      const exceedsPaginationCapacity = paginationCapacity >= hasAppsInCurrentTab;
      const paginationNotAvailable = (hasNextPageFalse && exceedsPaginationCapacity) || !this.appPaginationSize;
      return paginationNotAvailable;
    },
    skip () {
      const { appPaginationSize } = this.appStore;
      return (appPaginationSize * (this.paginationInfo.currentPage - 1)) + this.paginationInfo.skip;
    },
    getPaginatedApps () {
      const { appPaginationSize } = this.appStore;
      const relevantApps = this.getApps;
      if (!relevantApps) return [];
      const appsByTab = relevantApps[this.currentTab] || [];
      const skip = this.skip;
      return appsByTab.slice(skip, skip + appPaginationSize);
    },

    /**
     * Calculates the total number of items that can be paginated based on the current page and pagination size.
     *
     * @return {number} The total number of items that can be paginated.
     */
    getPaginationCapacity () {
      const { appPaginationSize } = this.appStore;
      const totalPaginatedCount = (this.paginationInfo.currentPage * appPaginationSize) + this.paginationInfo.skip;
      return totalPaginatedCount;
    },

    paginationView: {
      get () {
        return this.appStore.appPaginationSize;
      },

      /**
       * Sets the pagination size for the current page.
       * Handles 3 cases:
       *  1. When Pagination size moves from All to Number.
       *
       *    - Current page is reset to 1
       *    - skip is 0 at this point
       *    - trim existing apps to the new pagination size
       *
       *  2. When Pagination size moves from Number to All.
       *
      *     - Api call is made
       *    - Current page is reset to 1
       *    - skip is 0
       *
       *  3. When Pagination size moves from Number to Number.
       *
       *    - Api call is made
       *    - Current page is reset to 1
       *    - skip is put to the new value, which will be equal to the (new pagination size * (current page - 1)) + existing skip
       * @example {
       *      currentPage: 2,
       *      paginationSize : 10,
       *      skip: 20,
       *      }
       *      const newSkip = (10 * (2 - 1)) + 20 = 30
       *
       * Every time pagination size changes, it fetches the new data. we move current page to 1
       * @param { number | null } val - The new pagination size.
       */
      async set (val) {
        const { appPaginationSize: existingAppPaginationSize } = this.appStore;
        this.appStore.setAppStore('appPaginationSize', val)
        if (!val) {
          this.appStore.setAppStore('appNextCursor', undefined)
        }
        if (existingAppPaginationSize && val) {
          const apps = this.getApps;
          const currentPage = this.paginationInfo.currentPage;
          const existingApps = apps[this.currentTab] ? apps[this.currentTab].slice(0, ((currentPage - 1) * existingAppPaginationSize + this.paginationInfo.skip)) : [];
          const nextCursor = existingApps ? {
            id: existingApps.length ? existingApps[existingApps.length - 1].offerId : undefined,
            partnerPortalStageTimestamp: existingApps.length ? existingApps[existingApps.length - 1].partnerPortalStageTimestamp : undefined
          } : undefined;
          this.appStore.setAppStore('appNextCursor', nextCursor)
        }
        const { responseCode, data: { applications } } = await this.getApplicationWithPagination();
        if (responseCode !== 200) return;
        if (existingAppPaginationSize) {
          const currentPage = this.paginationInfo.currentPage;
          this.paginationInfo.currentPage = 1;
          if (!val) {
            this.paginationInfo.skip = 0;
          } else {
            const { currentProduct } = this.appStore;
            const apps = this.getApps;
            let existingPaginatedApps = [];
            if (apps && apps[this.currentTab]) {
              existingPaginatedApps = apps[this.currentTab].slice(0, ((currentPage - 1) * existingAppPaginationSize + this.paginationInfo.skip));
            }
            this.paginationInfo.skip = existingPaginatedApps.length || 0;
            if (applications[currentProduct] && applications[currentProduct][this.currentTab]) {
              applications[currentProduct][this.currentTab] = [...(existingPaginatedApps || []), ...applications[currentProduct][this.currentTab]];
            }
          }
        } else {
          const { currentProduct } = this.appStore;
          const apps = this.getApps;
          if (applications[currentProduct] && applications[currentProduct][this.currentTab]) {
            applications[currentProduct][this.currentTab] = apps[this.currentTab].slice(0, val);
          }
        }
        this.appStore.setAppStore('apps', applications)
      }
    },
    appsLoaded () {
      return this.appStore.appsLoaded;
    },
    isMobile () {
      return this.nuxtStore.isMobile;
    },
    activeTab () {
      return this.tabs[this.currentTab].component;
    },
    getNextCursor () {
      const paginatedApps = this.getPaginatedApps;
      if (!paginatedApps.length) return undefined;
      const lastApp = paginatedApps[paginatedApps.length - 1];
      return {
        id: lastApp.offerId,
        partnerPortalStageTimestamp: lastApp.partnerPortalStageTimestamp
      }
    },
    currentProductSettings () {
      return this.domainStore.products.find(({ value }) => value === this.appStore.currentProduct)?.settings;
    },
    getApps () {
      const { currentProduct } = this.appStore;
      if (!this.appStore.apps[currentProduct]) {
        this.appStore.setAppStore('currentProduct', this.appStore.products[0]);
        return this.appStore.apps[this.appStore.products[0]];
      }
      return this.appStore.apps[currentProduct];
    },
    getFields () {
      return (this.appStore.fields && this.appStore.currentProduct && this.currentTab) ? (this.appStore.fields[this.appStore.currentProduct][this.currentTab]) : {};
    },
  },
  async mounted () {
    this.appStore.setAppsNotLoaded();
    const url = window.location.href;
    if (url.includes('?')) {
      const tabParam = url.match('[?&]' + 'tab' + '=([^&]+)');
      const currentProduct = url.match('[?&]' + 'product' + '=([^&]+)');
      const subTabParam = url.match('[?&]' + 'subtab' + '=([^&]+)');
      if (tabParam && this.tabs[tabParam[1]]) {
        this.appStore.setAppStore('currentTab', tabParam[1])
      }
      if (currentProduct) {
        this.appStore.setAppStore('currentProduct', currentProduct[1])
      }
      if (subTabParam && this.subTabs[subTabParam[1]]) {
        this.appStore.setAppStore('currentSubTab', subTabParam[1])
      }
      this.$router.replace(window.location.pathname);
    }
    this.appStore.setAppStore('appNextCursor', undefined)
    const { responseCode, data: { applications, fieldList, properties: { onlineLender, allowExport, userViewSettings } } } = await this.getApplicationWithPagination();
    if (responseCode !== 200) return;
    this.appStore.setAppStore('apps', applications)
    this.appStore.setAppStore('fields', fieldList)
    this.appStore.setAppStore('products', Object.keys(fieldList))
    this.appStore.setOnlineLenderFlag(onlineLender);
    this.appStore.setAllowExportFlag(allowExport);
    this.appStore.setUserViewSettings(userViewSettings);
    this.appStore.setAppStore('currentTab', this.appStore.currentTab || this.currentTab)

    // if (!this.appStore.currentTab) {
    //   this.appStore.setAppStore('currentTab', this.currentTab)
    // }
    // else {
    //   this.appStore.setAppStore('currentTab', this.appStore.currentTab)
    // }

    if (!this.appStore.currentProduct) {
      this.appStore.setAppStore('currentProduct', this.appStore.products[0])
    }
    this.appStore.setUserHiddenFields();

    EventBus.$on('refreshApp', this.refreshApp);

    EventBus.$on('fetchAndRefreshAppList', this.fetchAndRefreshAppList);

    EventBus.$on('changeProduct', this.unmount);
    EventBus.$on('changeProductDone', this.mount);

    this.isMounted = true;
    this.appStore.setAppsLoaded();

    this.$nextTick(() => {
      this.handleScrollTable();
    });
    this.$watch('appsLoaded', function (newVal, oldVal) {
      if (newVal === true) this.handleScrollTable();
    })

    if (this.nuxtStore.locale === 'hk') {
      const { responseCode: ffResponseCode, data: ffData } = await this.getFeatureFlags();

      if (ffResponseCode === 200) {
        this.ffStore.setFeatureFlag(ffData);
      }
    }
  },

  unmounted () {
    EventBus.$off('refreshApp', this.refreshApp);
    EventBus.$off('changeProduct', this.unmount);
    EventBus.$off('changeProductDone', this.mount);
    EventBus.$off('fetchAndRefreshAppList', this.fetchAndRefreshAppList);
    this.removeScrollListener();
  },
  methods: {
    getFieldLabel (fieldID) {
      if (this.contentText[fieldID]) return this.contentText[fieldID].label;
      return fieldID;
    },
    getButtonLabel (index) {
      if (this.miscButtonLabels[index]) return this.miscButtonLabels[index].label;
      return index;
    },
    getTexts (index) {
      if (this.miscTexts[index]) return this.miscTexts[index].label;
      return index;
    },
    sectionLength (section) {
      if (!this.getApps) return 0;
      return this.getApps.count[section];
    },
    async changeSubTab (subTab) {
      this.appStore.setAppStore('currentSubTab', subTab);
      await this.fetchApp();
    },
    async changeTab (tab) {
      this.paginationInfo.currentPage = 1;
      this.paginationInfo.skip = 0;
      this.appStore.setAppStore('appNextCursor', undefined);
      this.appStore.setAppStore('currentTab', tab);
      const subTab = (this.subTabs[tab]) ? Object.keys(this.subTabs[tab])[0] : undefined;
      this.appStore.setAppStore('currentSubTab', subTab);
      await this.fetchApp();
    },

    async getNextPageApplication () {
      const cursor = this.getNextCursor;
      this.paginationInfo.currentPage += 1;
      if (this.skip < this.getApps[this.currentTab].length) return;
      this.appStore.setAppStore('appNextCursor', cursor);
      await this.fetchApp();
    },
    getPreviousPageApplication () {
      if (this.paginationInfo.currentPage === 1 && this.paginationInfo.skip === 0) return;
      const { appPaginationSize } = this.appStore;
      if (this.paginationInfo.currentPage === 1 && this.paginationInfo.skip !== 0) {
        this.paginationInfo.skip = (this.paginationInfo.skip - appPaginationSize) < 0 ? 0 : this.paginationInfo.skip - appPaginationSize;
      } else {
        this.paginationInfo.currentPage -= 1;
      }
      if (this.paginationInfo.currentPage === 1 && this.paginationInfo.skip === 0) {
        this.appStore.setAppStore('appNextCursor', undefined);
      }
    },

    handleScrollTable () {
      const tableWrapper = document.getElementById('tableWrapper');
      const paginationDiv = document.getElementById('application-pagination-div');

      if (!tableWrapper || !paginationDiv) return;

      this.scrollHandler = () => {
        if (tableWrapper.scrollTop !== 0) {
          paginationDiv.style.display = 'none';
        } else {
          paginationDiv.style.display = 'flex';
        }
      };

      // Remove existing listener if any
      tableWrapper.removeEventListener('scroll', this.scrollHandler);

      // Add new listener
      tableWrapper.addEventListener('scroll', this.scrollHandler, { passive: true });
    },

    removeScrollListener () {
      const tableWrapper = document.getElementById('tableWrapper');
      if (tableWrapper && this.scrollHandler) {
        tableWrapper.removeEventListener('scroll', this.scrollHandler);
        this.scrollHandler = null; // Clear the reference
      }
    },

    async fetchApp () {
      this.appStore.setAppsNotLoaded();
      const { responseCode, data: { applications, fieldList, properties: { onlineLender, allowExport, userViewSettings } } } = await this.getApplicationWithPagination();
      const { appPaginationSize, currentProduct } = this.appStore;
      if (this.currentTab === 'given') {
        const apps = this.getApps;
        let existingPaginatedApps = [];
        if (apps && apps[this.currentTab]) {
          existingPaginatedApps = (appPaginationSize && this.skip !== 0) ? apps[this.currentTab] : [];
        }
        if (applications[currentProduct] && applications[currentProduct][this.currentTab]) {
          applications[currentProduct][this.currentTab] = [...(existingPaginatedApps || []), ...applications[currentProduct][this.currentTab]];
        }
      }
      this.appStore.setAppStore('apps', applications);
      this.appStore.setAppsLoaded();
      this.appStore.setOnlineLenderFlag(onlineLender);
      this.appStore.setAllowExportFlag(allowExport);
      this.appStore.setUserViewSettings(userViewSettings);
      if (fieldList) {
        this.appStore.setAppStore('fields', fieldList);
        this.appStore.setUserHiddenFields();
      }
    },
    
    refreshApp(newApps) {
      this.appStore.refreshApp(this.appStore.currentProduct, newApps[this.appStore.currentProduct])
    },

    fetchAndRefreshAppList() {
      this.fetchApp();
    },

    mount() {
      this.appStore.setUserHiddenFields();
      this.paginationInfo.currentPage = 1;
      this.paginationInfo.skip = 0;
      this.isMounted = true;
    },

    unmount() {
      this.isMounted = false;
    }
  },
}
</script>
